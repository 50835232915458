main {
  padding-top: 10vh;
  min-height: 100vh;
}

.it-works-svg-style {
  max-width: 60%;
}

.it-works-style {
  border: 2px solid #005288;
  border-radius: 3%;
  transform: scale(94%);
  z-index: -1;
}

.road-map-style {
  border-radius: 5%;
  transform: scale(80%);
  z-index: -1;
}

.tweet-container {
  width: 100px;
  /* height: 50px; */
  transform: scale(50%);
}

.tweet-container iframe {
  width: 80%;
  height: auto;
}

.road-map-svg-style {
  width: 80%;

  z-index: -1;
}

.what-you-get-style {
  border: 0.4rem solid #005288;
  border-radius: 5%;
}

.square-payment-container {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
}

.square-credit-card-container {
  background-color: #771520;
  font-size: 14px;
  color: #fff;
}

.square-credit-card-container:hover {
  background-color: #530f16;
}

.pleaseWrap {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  align-items: stretch;
  gap: 20px;
  margin: 2%;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .other {
    font-size: 14px;
  }
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(219, 217, 217, 0.9);
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
}

.credit-card-icon {
  width: 98%;
  height: auto;
  padding: 0.5%;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #dbd9d9;
}

.accordion-title:hover {
  background-color: #c8c5c5;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: #edeaea;
  height: 0;
  overflow: hidden;
  transition: height 1.5s ease-out;
}

.accordion-content.active {
  transition: height 1.5s ease-out;
  height: auto;
}

@media (max-width: 440px) {
  .text-secondary-signup {
    font-size: 12px;
  }
}

/* CSS for screen width 600px or greater */
@media (min-width: 600px) {
  .large-screen-content {
    display: block;
  }

  .small-screen-content {
    display: none;
  }
}

/* CSS for screen width less than 600px */
@media (max-width: 599px) {
  .large-screen-content {
    display: none;
  }

  .small-screen-content {
    display: block;
  }
}
